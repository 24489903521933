exports.components = {
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-accueil-js": () => import("./../../../src/pages/accueil.js" /* webpackChunkName: "component---src-pages-accueil-js" */),
  "component---src-pages-alcools-js": () => import("./../../../src/pages/alcools.js" /* webpackChunkName: "component---src-pages-alcools-js" */),
  "component---src-pages-carte-des-vins-js": () => import("./../../../src/pages/carte-des-vins.js" /* webpackChunkName: "component---src-pages-carte-des-vins-js" */),
  "component---src-pages-cocktails-js": () => import("./../../../src/pages/cocktails.js" /* webpackChunkName: "component---src-pages-cocktails-js" */),
  "component---src-pages-contact-js": () => import("./../../../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-food-js": () => import("./../../../src/pages/food.js" /* webpackChunkName: "component---src-pages-food-js" */),
  "component---src-pages-galerie-js": () => import("./../../../src/pages/galerie.js" /* webpackChunkName: "component---src-pages-galerie-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-mentions-legales-js": () => import("./../../../src/pages/mentions-legales.js" /* webpackChunkName: "component---src-pages-mentions-legales-js" */),
  "component---src-pages-softs-js": () => import("./../../../src/pages/softs.js" /* webpackChunkName: "component---src-pages-softs-js" */)
}

